import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { pick } from 'lodash';
import { RsFormatPaginatedListRequestBody, RsUtils } from '@lib/rs-ngx';
import { CreateItem } from '@distribution-center/items/shared/models/create-item';
import { ItemDetails } from '@distribution-center/shared/models/item-details';
import { GetItemsQueryParams } from '@distribution-center/items/shared/models/get-items-query-params';
import { UpdateItem } from '@distribution-center/items/shared/models/update-item';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';
import { ItemExternalInfo } from '@distribution-center/shared/models/item-external-info';

@Injectable()
export class ItemService {
  constructor(private readonly http: HttpClient) {
  }

  public readonly createItem = (item: CreateItem, document?: File): Observable<ItemDetails> => {
    const url = `${environment.apiGatewayUrl}/distribution-center/item`;
    const body = this.createItemFormData(item, document);

    return this.http.post<ItemDetails>(url, body);
  };

  public readonly getAllActiveItems = (): Observable<RsFormatPaginatedListRequestBody<ItemDetails[]>> => {
    return this.getItems({ active: 'ACTIVE', _pageSize: 9999 });
  };

  public readonly getItems = (queryParams: Partial<GetItemsQueryParams>): Observable<RsFormatPaginatedListRequestBody<ItemDetails[]>> => {
    const url = `${environment.apiGatewayUrl}/distribution-center/item`;

    const params = RsUtils.trimEmptyHttpParams(queryParams);

    return this.http.get<ItemDetails[]>(url, { observe: 'response', params })
      .pipe(rsFormatPaginatedListRequestBody());
  };

  public readonly updateItem = (itemDetails: ItemDetails): Observable<ItemDetails> => {
    const url = `${environment.apiGatewayUrl}/distribution-center/item/${itemDetails.id}`;
    const body = this.mapDetailsToUpdateBody(itemDetails);

    return this.http.put<ItemDetails>(url, body);
  };

  public readonly getItemDocument = (itemId: string): Observable<HttpResponse<Blob>> => {
    const url = `${environment.apiGatewayUrl}/distribution-center/item/${itemId}/document`;
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  };

  public readonly getItemDetails = (id: string): Observable<ItemDetails> => {
    return this.http.get<ItemDetails>(`${environment.apiGatewayUrl}/distribution-center/item/${id}`);
  };

  public readonly getItemExternalInfo = (id: string): Observable<ItemExternalInfo> => {
    return this.http.get<ItemExternalInfo>(`${environment.apiGatewayUrl}/distribution-center/item/${id}/external-info`);
  }

  private createItemFormData(item: CreateItem, document?: File): FormData {
    const formData = new FormData();
    formData.append('item', this.objectToBlob(item));
    if (document) {
      formData.append('document', document, document.name);
    }
    return formData;
  }

  private mapDetailsToUpdateBody(itemDetails: ItemDetails): UpdateItem {
    return pick(
      itemDetails,
      'active',
      'description',
      'minimalStock',
      'version',
    );
  }

  private objectToBlob(item: object): Blob {
    return new Blob([JSON.stringify(item)], { type: 'application/json' });
  }
}
